import Parse from "parse";
import React from "react";
import { Location, useParseFunction } from "..";













export function useCloudCodeGeoCodingReverse(
  latitude,
  longitude,
  icon
) {
  const { result, error, loading } = useParseFunction(
    latitude && longitude ? "ride-sharing-geocode-reverse" : null,
    {
      latitude,
      longitude,
    },
    [latitude, longitude]
  );

  return React.useMemo(() => {
    return {
      error,
      loading,
      result: (result || []).map((result) => {
        return new Location({
          title: result.title,
          description: result.description,
          location: new Parse.GeoPoint(result.location),
          icon: icon,
        });
      }),
    };
  }, [result, error, loading]);
}
