import { Capacitor } from "@capacitor/core";

export const PUBLIC_TRANSPORT_HAFAS_API_URL =
  "https://public-transport-hafas.apps.openinc.dev";

const fakeNeedsSaveArea = false;
const needsSaveArea = fakeNeedsSaveArea || Capacitor.getPlatform() === "ios";

export const SAVE_AREA_TOP = needsSaveArea ? 30 : 0;
export const SAVE_AREA_BOTTOM = needsSaveArea ? 20 : 0;
export const SAVE_AREA_CORNER = needsSaveArea ? 13 : 0;
