const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\PublicTransportList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Button, Drawer, List, Timeline } from "antd";
import React from "react";
import { ListEmpty, MetaIcon, } from "..";

export function PublicTransportList({
  data,
}

) {
  const t = useTranslation();

  const [details, setDetails] =
    React.useState(null);

  if (_optionalChain([data, 'optionalAccess', _ => _.length]) === 0) {
    return (
      React.createElement(ListEmpty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, t("rs:search.results.public_transport_empty"))
    );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(List, {
        loading: !data,
        dataSource: data,
        renderItem: (item) => (
          React.createElement(List.Item, {
            onClick: () => (_optionalChain([item, 'access', _2 => _2.stops, 'optionalAccess', _3 => _3.length]) > 0 ? setDetails(item) : null),
            extra: 
              _optionalChain([item, 'access', _4 => _4.stops, 'optionalAccess', _5 => _5.length]) > 0 ? (
                React.createElement(Button, {
                  key: "details",
                  icon: React.createElement(MetaIcon, { icon: "zentrale_info2", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} ),
                  children: "Details", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
                )
              ) : undefined
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          
            , React.createElement(List.Item.Meta, { title: item.title, description: item.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      )
      , React.createElement(Drawer, {
        visible: !!details,
        title: _optionalChain([details, 'optionalAccess', _6 => _6.title]),
        placement: "bottom",
        height: Math.min(
          60 + (_optionalChain([details, 'optionalAccess', _7 => _7.stops, 'optionalAccess', _8 => _8.length]) || 0) * 70,
          window.innerHeight
        ),
        onClose: () => setDetails(null), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      
        , React.createElement(Timeline, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
          , details &&
            details.stops.map((stop) => (
              React.createElement(Timeline.Item, { color: "gray", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
                , React.createElement('span', { style: { fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, stop.title)
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
                , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}, stop.description)
              )
            ))
        )
      )
    )
  );
}
