const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserQuickMessage.tsx";import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Divider, Form, Input, List, message, Modal } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import styled from "styled-components";
import { QuickMessage } from "..";


const Container = styled.div`
  padding: 24px 0;
`;

export function UserQuickMessage({}) {
  const t = useTranslation();

  const query = React.useMemo(
    () =>
      new Parse.Query(QuickMessage)
        .equalTo("user", Parse.User.current() )
        .ascending("label"),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  const [isCreate, setCreate] = React.useState(false);
  const [form] = Form.useForm();

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      , React.createElement(List, {
        loading: loading,
        dataSource: result,
        renderItem: (item) => {
          const onDelete = () => {
            Modal.confirm({
              title: t("rs:messenger.quickmessages.delete.confirm_title"),
              content: t("rs:messenger.quickmessages.delete.confirm_content"),
              okText: t("rs:messenger.quickmessages.delete.ok"),
              okType: "danger",
              onOk: () => {
                item.destroy().then(
                  (ok) => {
                    message.success(
                      t("rs:messenger.quickmessages.delete.success")
                    );
                    reload();
                  },
                  (error) => {
                    console.error(error);
                    message.success(
                      t("rs:messenger.quickmessages.delete.error")
                    );
                  }
                );
              },
            });
          };

          return (
            React.createElement(List.Item, {
              extra: React.createElement(Icon, { key: "delete", icon: "ai:delete", onClick: onDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            
              , React.createElement(List.Item.Meta, {
                title: item.get("label"),
                description: item.get("message"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 75}} )

      , React.createElement('div', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        , React.createElement(Button, {
          type: "primary",
          size: "large",
          children: t("rs:messenger.quickmessages.create.action"),
          onClick: () => {
            setCreate(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        )
      )

      , React.createElement(Modal, {
        visible: isCreate,
        onCancel: () => {
          setCreate(false);
        },
        title: t("rs:messenger.quickmessages.create.title"),
        okText: t("opendash:ui.save"),
        onOk: () => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();

              const msg = new QuickMessage(values);

              return msg.save();
            })
            .then(() => {
              reload();
              setCreate(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
      
        , React.createElement(Form, { form: form, layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
          , React.createElement(Form.Item, {
            name: "label",
            label: t("rs:messenger.quickmessages.create.label_label"),
            rules: [
              {
                required: true,
                message: t("rs:messenger.quickmessages.create.label_required"),
              },
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
          
            , React.createElement(Input, {
              placeholder: t(
                "rs:messenger.quickmessages.create.label_placeholder"
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
            )
          )
          , React.createElement(Form.Item, {
            name: "message",
            label: t("rs:messenger.quickmessages.create.message_label"),
            rules: [
              {
                required: true,
                message: t(
                  "rs:messenger.quickmessages.create.message_required"
                ),
              },
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          
            , React.createElement(Input.TextArea, {
              placeholder: t(
                "rs:messenger.quickmessages.create.message_placeholder"
              ),
              autoSize: { minRows: 2, maxRows: 6 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
            )
          )
        )
      )
    )
  );
}
