const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideOfferRequest.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useForceRender, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import {

  Button,
  Descriptions,

  Drawer,
  List,
  message,
  Image,
  Spin,
} from "antd";
import moment from "moment";
import Parse from "parse";
import React from "react";
import { useNavigate } from "react-router";
import {
  Chat,
  getProfileImage,
  Offer,

  useParseGet,
  usePlacesNearLocation,
  useRideSearchDate,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
} from "..";







const buttonStyle = {
  width: "100%",
};

export const RideOfferRequest = React.memo(
  ({ visible, offerId, onClose }) => {
    const t = useTranslation();
    const navigate = useNavigate();
    const forceRender = useForceRender();

    const { result: offer, loading } = useParseGet(Offer, visible && offerId, {
      includes: ["user", "user.rsProfile"],
    });

    const [startLocation] = useRideSearchStart();
    const [endLocation] = useRideSearchEnd();
    const [time] = useRideSearchTime();
    const [date] = useRideSearchDate();

    const [start, setStart] = React.useState();
    const [end, setEnd] = React.useState();

    const placesBaseQuery = React.useMemo(
      () =>
        offer ? (offer.get("places").query() ) : null,
      [offer]
    );

    const {
      places,
      distances,
      loading: placesLoading,
    } = usePlacesNearLocation(
      start ? endLocation : startLocation,
      placesBaseQuery
    );

    const user = _optionalChain([offer, 'optionalAccess', _ => _.get, 'call', _2 => _2("user")]);
    const avatar = getProfileImage(user);

    const profile = _optionalChain([user, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("rsProfile")]);

    React.useEffect(() => {
      if (user) {
        user
          .get("rsProfile")
          .fetch()
          .then(() => {
            forceRender();
          });
      }
    }, [user]);

    React.useEffect(() => {
      setStart(null);
      setEnd(null);
    }, [offerId]);

    const drawerProps = {
      visible,
      placement: "bottom" ,
      onClose,
      headerStyle: {
        padding: "8px 20px",
      },
      height: 600,
      title: (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , React.createElement('div', {
            style: {
              display: "inline-block",
              height: 40,
              float: "left",
              marginRight: 10,
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
          
            , avatar ? (
              React.createElement(Image, {
                width: 40,
                height: 40,
                src: avatar,
                style: { borderRadius: "50%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
              )
            ) : (
              React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}} )
            )
          )

          , React.createElement('div', {
            style: { display: "inline-block", height: 40, lineHeight: "40px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
          
            , t("rs:offer.request.title", { name: _optionalChain([user, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("name")]) })
          )
        )
      ),
    };

    if (loading || placesLoading || !profile || !user) {
      return (
        React.createElement(Drawer, { ...drawerProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
          , React.createElement('div', { style: { textAlign: "center", paddingTop: 40 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
            , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 138}} )
          )
        )
      );
    }

    if (start && end) {
      return (
        React.createElement(Drawer, { ...drawerProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}, t("rs:offer.request.confirm_text"))
          , React.createElement(Descriptions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
            , _optionalChain([profile, 'optionalAccess', _7 => _7.has, 'call', _8 => _8("car")]) && (
              React.createElement(Descriptions.Item, {
                label: t("parse-custom:classes.RideSharingProfile.fields.car"),
                children: _optionalChain([profile, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("car")]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
              )
            )

            , _optionalChain([profile, 'optionalAccess', _11 => _11.has, 'call', _12 => _12("licenseYear")]) && (
              React.createElement(Descriptions.Item, {
                label: t(
                  "parse-custom:classes.RideSharingProfile.fields.licenseYear"
                ),
                children: _optionalChain([profile, 'optionalAccess', _13 => _13.get, 'call', _14 => _14("licenseYear")]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
              )
            )

            , _optionalChain([profile, 'optionalAccess', _15 => _15.has, 'call', _16 => _16("offerAccessibilityWheelchair")]) && (
              React.createElement(Descriptions.Item, {
                label: t(
                  "parse-custom:classes.RideSharingProfile.fields.offerAccessibilityWheelchair"
                ),
                children: 
                  _optionalChain([profile, 'optionalAccess', _17 => _17.get, 'call', _18 => _18("offerAccessibilityWheelchair")]) ? "Ja" : "Nein"
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
              )
            )

            , React.createElement(Descriptions.Item, {
              label: t("rs:offer.request.start_place"),
              children: `${start.get("name")} (${start.get("type")})`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
            )

            , React.createElement(Descriptions.Item, {
              label: t("rs:offer.request.end_place"),
              children: `${end.get("name")} (${end.get("type")})`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
            )

            , React.createElement(Descriptions.Item, {
              label: t("rs:ride.date_label"),
              children: moment(date).format("DD.MM.YYYY"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
            )

            , React.createElement(Descriptions.Item, {
              label: t("rs:ride.time_label"),
              children: time, __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
            )
          )
          , React.createElement(Button, {
            type: "primary",
            style: buttonStyle,
            children: t("rs:offer.request.confirm_action"),
            onClick: () => {
              const chat = new Chat({
                offer,
                start,
                end,
                time,
                date,
                locked: false,
                lastMessageAt: new Date(),
                lastMessageUser: Parse.User.current(),
              });
              chat.save().then(
                () => {
                  // TODO
                  setTimeout(() => {
                    message.success(t("rs:offer.request.success"));
                    navigate("/ride-sharing/messenger?chat=" + chat.id);
                  }, 500);
                },
                (error) => {
                  message.error(t("rs:offer.request.error"));
                  console.error(error);
                }
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}
          )
        )
      );
    }

    return (
      React.createElement(Drawer, { ...drawerProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
          , t(
            start
              ? "rs:offer.request.select_end_place"
              : "rs:offer.request.select_start_place"
          )
        )

        , React.createElement(List, {
          loading: placesLoading,
          dataSource: places,
          renderItem: (place) => (
            React.createElement(List.Item, {
              extra: 
                React.createElement(Button, {
                  children: t("rs:offer.request.select_place"),
                  onClick: () => {
                    if (start) {
                      setEnd(place);
                    } else {
                      setStart(place);
                    }
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 246}}
                )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
            
              , React.createElement(List.Item.Meta, {
                avatar: 
                  React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 260}}
                    , React.createElement('div', { style: { fontSize: 20, textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 261}}
                      , React.createElement(Icon, { icon: place.get("icon") || "fa:map-marker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}} )
                    )
                    , React.createElement('div', { style: { fontSize: 12, textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 264}}
                      , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}, distances[place.id], "m")
                    )
                    , React.createElement('div', { style: { fontSize: 10, textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 267}}, "Luftlinie"

                    )
                  )
                ,
                title: 
                  place.get("highlight") ? (
                    React.createElement(React.Fragment, null
                      , React.createElement(Icon, {
                        icon: "ai:star",
                        style: { color: "#40a9ff", marginRight: 5 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 275}}
                      )
                      , place.get("name")
                    )
                  ) : (
                    place.get("name")
                  )
                ,
                description: place.get("type"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
              )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
        )
        , React.createElement('p', { style: { fontSize: ".8em", textAlign: "center", paddingTop: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
          , React.createElement(Icon, { icon: "ai:star", style: { color: "#40a9ff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 291}} )
          , t("rs:ride.places_highlight_desc")
        )
      )
    );
  }
);
