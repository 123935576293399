import { useDebounce } from "@opendash/core";
import Parse from "parse";
import React from "react";
import { Location, useCurrentPosition, useParseFunction } from "..";













export function useCloudCodeGeoCoding(query) {
  const queryDebounced = useDebounce(query, 500);
  const [latitude, longitude] = useCurrentPosition();

  const { result, error, loading } = useParseFunction(
    query ? "ride-sharing-geocode" : null,
    {
      query: queryDebounced,
      latitude,
      longitude,
    },
    [queryDebounced]
  );

  return React.useMemo(() => {
    return {
      error,
      loading,
      result: (result || []).map((result) => {
        return new Location({
          title: result.title,
          description: result.description,
          location: new Parse.GeoPoint(result.location),
          icon: "fa:map-marker-alt",
        });
      }),
    };
  }, [result, error, loading]);
}
