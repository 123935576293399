const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AppLayout.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  getUserProfile,
  SAVE_AREA_TOP,
  SAVE_AREA_BOTTOM,
  SAVE_AREA_CORNER,
} from "..";

const Container = styled.div`
  width: 100%;
  padding-top: ${SAVE_AREA_TOP}px;
  padding-bottom: 80px;

  user-select: none;
`;

const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;

  width: 100%;
  height: calc(60px + ${SAVE_AREA_BOTTOM}px);
  padding-left: ${SAVE_AREA_CORNER}px;
  padding-right: ${SAVE_AREA_CORNER}px;
  padding-bottom: ${SAVE_AREA_BOTTOM}px;

  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  z-index: 99;

  a {
    display: block;
    background: white;
    width: 100%;
    text-align: center;
    color: inherit;
    height: 60px;
    padding: 6px 0;

    &.active {
      border-bottom: 3px solid #1890ff;
      color: #1890ff;
      font-weight: bold;
    }
  }
`;

const IconHolder = styled.div`
  font-size: 20px;
  line-height: 26px;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

const BodyContainer = styled.div``;

export function AppLayout({ children }) {
  const t = useTranslation();

  const offerDisabled = _optionalChain([getUserProfile, 'call', _ => _(), 'optionalAccess', _2 => _2.offerDisabled]);
  const homeDisabled = _optionalChain([getUserProfile, 'call', _3 => _3(), 'optionalAccess', _4 => _4.homeDisabled]);

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      , React.createElement(NavigationContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        , !homeDisabled && (
          React.createElement(NavLink, { to: "home", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
              , React.createElement(Icon, { icon: "ai:home", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}, t("rs:navigation.home"))
          )
        )

        , React.createElement(NavLink, { to: "search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
            , React.createElement(Icon, { icon: "ai:search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}, t("rs:navigation.search"))
        )

        , !offerDisabled && (
          React.createElement(NavLink, { to: "offer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
              , React.createElement(Icon, { icon: "ai:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}, t("rs:navigation.offer"))
          )
        )

        , React.createElement(NavLink, { to: "messenger", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
            , React.createElement(Icon, { icon: "ai:message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}, t("rs:navigation.messenger"))
        )

        , React.createElement(NavLink, { to: "profile", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
            , React.createElement(Icon, { icon: "ai:setting", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}, t("rs:navigation.settings"))
        )
      )
      , React.createElement(BodyContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}, children)
    )
  );
}
