import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";


export async function initPushNotifications($user) {
  switch (Capacitor.getPlatform()) {
    case "web":
      return initPushNotificationsWeb($user);

    case "android":
    case "ios":
      return initPushNotificationsNative($user);
  }
}

async function initPushNotificationsWeb($user) {
  await $user.registerWebPush();
}

async function initPushNotificationsNative($user) {
  await PushNotifications.addListener("registration", (token) => {
    $user.handlePushSubscription(`capacitor-${Capacitor.getPlatform()}`, {
      token: token.value,
    });
  });

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", JSON.stringify(err.error, null, 2));
  });

  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) => {
      console.log(
        "Push notification received: ",
        JSON.stringify(notification, null, 2)
      );
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log(
        "Push notification action performed",
        notification.actionId,
        notification.inputValue
      );
    }
  );

  await registerNotificationsNative();
}

async function registerNotificationsNative() {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
}
