import Parse from "parse";

Parse.initialize("kommuter");
Parse.serverURL = "https://parse-kommuter-production.apps.openinc.dev/parse";
// Parse.serverURL = "https://parse-kommuter-demo.apps.openinc.dev/parse";
// Parse.serverURL = "https://parse-kommuter-dev.apps.openinc.dev/parse";
// Parse.serverURL = "https://parse-kommuter-omi.apps.openinc.dev/parse";

// Parse.serverURL = "http://192.168.178.83:1337/parse";

Parse.enableLocalDatastore();
