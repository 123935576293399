import React from "react";

import { Geolocation } from "@capacitor/geolocation";

export function useCurrentPosition() {
  const [state, setState] = React.useState([0, 0]);

  React.useEffect(() => {
    let id;

    Geolocation.getCurrentPosition()
      .then((position) => {
        setState([position.coords.latitude, position.coords.longitude]);
      })
      .catch((error) => {
        console.error("Geolocation.getCurrentPosition() Error:", error);
      });

    Geolocation.watchPosition({ timeout: 10000 }, (position, error) => {
      if (error) {
        return console.error("Geolocation.watchPosition() Error", error);
      }

      setState([position.coords.latitude, position.coords.longitude]);
    })
      .then((watchId) => {
        id = watchId;
      })
      .catch((error) => {
        console.error("Geolocation.watchPosition() Error:", error);
      });

    return () => {
      if (id) {
        Geolocation.clearWatch({ id }).catch((error) => {
          console.error("Geolocation.clearWatch() Error:", error);
        });
      }
    };
  }, []);

  return state;
}
