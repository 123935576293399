import "./serivce-worker";

export { initPushNotifications } from "./PushService";

export const app = {};

init().catch((error) => {
  console.error(error);
});

async function init() {}
