const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\PlacesInput.tsx";import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Checkbox, Drawer, List } from "antd";
import React from "react";












export function PlacesInput({
  pool,
  value,
  onChange,
  label,
  description,
  urlParam,
  style,
}) {
  const t = useTranslation();

  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  const placesSorted = [...pool].sort((a, b) =>
    a.get("highlight") && b.get("highlight") ? 0 : a.get("highlight") ? -1 : 1
  );

  React.useEffect(() => {
    onChange([...pool]);
  }, [pool]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        size: "large",
        style: style,
        children: t("rs:ride.places_select", {
          context: value.length > 0 ? "selection" : undefined,
          count: value.length,
        }),
        disabled: !pool || pool.length === 0,
        title: label,
        onClick: (e) => setOpen(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      )

      , React.createElement(Drawer, {
        visible: open,
        title: label,
        placement: "bottom",
        height: window.innerHeight || 400,
        onClose: () => {
          setOpen(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      
        , React.createElement(Button, {
          style: { float: "right", marginLeft: 10, marginBottom: 10 },
          children: t("rs:ride.places_select", {
            context: value.length > 0 ? "none" : "all",
          }),
          disabled: !pool || pool.length === 0,
          title: label,
          onClick: (e) => {
            if (value.length > 0) {
              onChange([]);
            } else {
              onChange([...pool]);
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        )

        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}, description)

        , React.createElement(List, {
          loading: !pool,
          dataSource: placesSorted,
          renderItem: (place) => (
            React.createElement(List.Item, {
              extra: 
                React.createElement(Checkbox, {
                  checked: value.some((p) => p.id === place.id),
                  onChange: (e) => {
                    const checked = e.target.checked;

                    if (checked) {
                      onChange([...value, place]);
                    } else {
                      onChange(value.filter((p) => p.id !== place.id));
                    }
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
                )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
            
              , React.createElement(List.Item.Meta, {
                avatar: 
                  React.createElement('div', { style: { fontSize: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
                    , React.createElement(Icon, { icon: place.get("icon") || "fa:map-marker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} )
                  )
                ,
                title: 
                  place.get("highlight") ? (
                    React.createElement(React.Fragment, null
                      , React.createElement(Icon, {
                        icon: "ai:star",
                        style: { color: "#40a9ff", marginRight: 5 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
                      )
                      , place.get("name")
                    )
                  ) : (
                    place.get("name")
                  )
                ,
                description: place.get("type"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
              )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
        )
        , React.createElement('p', { style: { fontSize: ".8em", textAlign: "center", paddingTop: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
          , React.createElement(Icon, { icon: "ai:star", style: { color: "#40a9ff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}} ), " "
          , t("rs:ride.places_highlight_desc")
        )
      )
    )
  );
}
